.MuiInputBase-root input {
  margin-left: 2.4rem;
  border: none;
}

.MuiButton-contained {
  width: 100%;
  height: 50px;
}

.searchcard {
  position: absolute;
  background-color: white;
  width: 100%;
  border: 1px solid gray;
  margin-top: 10px;
  border-radius: 5px;
  height: 200px;
  overflow-y: scroll;
}

.patieltlist {
  padding: 5px;
}

.patieltlist:hover {
  padding: 5px;
  background-color: #c9e6ff;
}
