.material-textfield {
    position: relative;
}

.pname,
.kindname {
    /* height: 30px; */
    width: 100%;
}
.taglink{
    color: black;
    text-decoration: none;
    font-size: 16px;
}
.sellingtab {
    height: 40px;
    width: 50%;
}

.formlabel {
    position: absolute;
    font-size: 13px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    color: gray;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
}

input {
    font-size: 1rem;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 1rem 0.7rem;
    color: gray;
    transition: 0.1s ease-out;
    width: 100%;
}

textarea {
    font-size: 1rem;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 1rem 0.7rem;
    color: gray;
    transition: 0.1s ease-out;
}

select {
    font-size: 1rem;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 1rem 0.7rem;
    color: gray;
    transition: 0.1s ease-out;
}

input:focus {
    border-color: #444444;
    border: 1px solid #444444;
}

textarea:focus {
    border-color: #444444;
    border: 1px solid #444444;
}

select:focus {
    border-color: #444444;
    border: px solid #444444;
}

input:focus+label {
    color: #444444;
    font-weight: 500;
    top: 0;
    transform: translateY(-50%) scale(.9);
}

textarea:focus+label {
    color: #444444;
    font-weight: 500;
    top: 0;
    transform: translateY(-50%) scale(.9);
}

select:focus+label {
    color: #444444;
    font-weight: 500;
    top: 0;
    transform: translateY(-50%) scale(.9);
}

input:not(:placeholder-shown)+label {
    top: 0;
    transform: translateY(-50%) scale(.9);
}

textarea:not(:placeholder-shown)+label {
    top: 0;
    transform: translateY(-50%) scale(.9);
}

select:not(:placeholder-shown)+label {
    top: 0;
    transform: translateY(-50%) scale(.9);
}

.centerdiv {
    background: rgba(243, 243, 243, 0.3);
    border: 1.5px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 8px;
}

.marksymbol {
    color: #f9fafb;
    background-color: #F9B506;
    width: 10px;
    height: 20px;
    padding: 0px 13px 0px 8px;
    border-radius: 50%;
    font-weight: bold;
    margin-right: 9px;
    margin-top: 4px;
}

.divtitle {
    color: #3976B3;
}

#submitbtn {
    background-color: #F9B506;
    margin: 5px;
}

#gobackbtn {
    background-color: white;
    margin: 5px;
}

#boxdiv {
    overflow-y: scroll;
}

.student__button {
    background-color: lightgrey;
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 600;
    text-decoration: none !important;
}

/* .student__button :hover {
    background-color: #3976b3;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    font-size: 20px;
    font-weight: 600;
} */

.employee__button {
    background-color: #3976b3;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    font-size: 10px;
    font-weight: 600;
}

.reg__heading {
    margin-top: 25px;
    font-size: 20px;
    font-weight: 500;
}

.emp__subheading {
    font-size: 20px;
    font-weight: 800;
}

.stausheading {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.righticon {
    color: black;
    padding: 5px 10px;
    font-size: 15px !important;
    border: 1px solid #e5e5e5;
    border-right: none;
    border-radius: 5px;
}

.righticon1 {
    color: black;
    padding: 5px 10px;
    font-size: 30px !important;
    border: 1px solid #e5e5e5;

    border-radius: 5px;
}

table {
    border: none !important;
}

.icons__orange {
    color: #ffa44e;
    padding-left: 15px;
    font-size: 15px !important;
}

.icons__black {

    padding-left: 15px;
    font-size: 15px !important;
}

.icons__red {
    color: #d65b5d;
    padding-left: 15px;
    font-size: 15px !important;
}

.table__less {
    margin-top: 20px;
}

.profile__image {
    height: 150px;
    width: 130px;
}

.icons__box {
    font-size: 15px !important;
    border: 1px solid lightgrey;
    padding: 10px 15px;
    background-color: #fafafa;
    border-radius: 5px;
}

.icons__box1 {
    font-size: 15px !important;
    border: 1px solid lightgrey;
    padding: 10px 15px;
    background-color: #fafafa;
    border-radius: 5px;
    margin-left: 25px
}

@media screen and (max-width:650px) {
    .centerdiv {
        background: rgba(243, 243, 243, 0.3);
        border: 1.5px solid #EBEBEB;
        box-sizing: border-box;
        border-radius: 8px;
        width: 115%;
    }

    .mobiledivmodify {
        margin-top: 18px;
    }

    .mobiledivmodify2 {
        margin-top: 11px;
    }
}

.adduserbtn {
    float: right;
}

.table {
    font-size: 12px;
    text-align: center;
}

.box {
    margin-top: 20px;

}

.table__top {
    margin-top: 20px;
}

.text__outlet {
    background-color: white;
    width: 100%
}

.text__outlet1 {
    background-color: white;
    width: 100%;
}


.box1 {
    margin-top: 20px;
    width: 1005;
}

.table__top td {
    color: black;
}

.button__management {
    border: none;
    border-radius: 10px;
    background-color: black;
    color: white;
    padding: 5px 18px;
    margin-top: 20px;

}

.table__header {
    background-color: #f3f3f3;
    padding: 10px 20px;
}

.button__addclass {
    padding: 7px 20px;
    border: none;
    border-radius: 10px;
    background-color: #3976b3;
    color: white;
}