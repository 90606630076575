.loginShowCase {
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.loginShowCase:before {
  content: "";
  background: url("../img/showCase.jpg") no-repeat center center/cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0.8;
  z-index: -1;
}
