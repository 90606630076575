.ImgCard img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
}
.ImgCard p {
  font-weight: bold;
}
.GalleryImgContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1.5rem;
}
