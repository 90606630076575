.card {
  position: relative;
  width: 380px;
  max-width: 960px;
  margin: auto;
  margin-top: 25vh;

  z-index: 10;
}
.card:before {
  content: "";
  background-color: rgb(112, 110, 110);
  position: absolute;
  max-width: 960px;
  width: 380px;
  height: 500px;
  margin: auto;
  border-radius: 20px;
  position: fixed;
  opacity: 0.9;
  z-index: -1;
}
.containers {
  padding: 5rem 2rem;
}
.actionContainer {
  margin-top: 40px;
}
.containers h1 {
  text-align: center;
}
.loginHead img {
 width: 70%;
}
.containers h1 span {
  color: rgb(22, 199, 199);
}
.containers h5 {
  color: red;
  margin: 20px 0;
  text-align: center;
  margin-top: -15px;
}
.search {
  position: relative;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 1rem;
}
.searchIcon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16px;
}
.input {
  padding: 0.5rem;
  width: 100%;
}

.forgetPassword {
  margin-top: 40px;
  color: white;
}
.forgetPassword p {
  margin-bottom: 10px;
  text-align: center;
  font-size: small;
}
.forgetPassword span {
  font-weight: 700;
}
p:hover {
  cursor: pointer;
}
.user {
  padding-top: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.doctor {
  background-color: rgb(22, 199, 199);
  color: white;
  padding: 0.4rem;
  border-radius: 50%;
}
.userContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: -50px;
}
.patient {
  background-color: rgb(22, 199, 199);
  color: white;
  padding: 0.4rem;
  border-radius: 50%;
}
.userContainer small {
  margin-top: 10px;
}
.bt{
  margin-top: 20px;
}