.dashboardHead{
    font-weight: bolder;
}
.employeeAnalyticsBox{
display: grid;
grid-template-columns: repeat(4,1fr);
grid-gap: 1.5rem;
margin-top: 30px
}
.employeeAnalytics h4{
    margin: 20px 0;
    font-weight: bolder;
}
.dashboardContent{
    display: grid;
    grid-template-columns: 1.5fr .5fr 1.5fr;
   
    margin-top: 50px;
}
.dashboardContent a{
    color: black;
}

.dashboardGalleryImg{
   display: grid;
   grid-template-columns: repeat(2,1fr);
   grid-gap: 1rem;
}
.dashboardGalleryImg img{
    width: 100%;
}
.dashboardGallery h3{
    font-weight: bolder;
}
/* /////// employee ana box */
.EmployeeAnaContainerB{
    background-color: skyblue;
}
.EmployeeAnaContainerG{
    background-color: lightgreen;
}
.EmployeeAnaContainerY{
    background-color: rgb(219, 121, 46);
}
.EmployeeAnaContainerR{
    background-color: lightcoral;
}
.EmployeeAnaContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 20px; */
    padding: 0 1rem;
}
.EmployeeAnaContent h2{
    text-align: right;
    color: white;
    font-size: 45px;
    font-weight: bolder;
    margin-top: 30px;
}
.EmployeeAnaContent h6{
    text-align: right;
    color: white;
    font-size: 20px;
    font-weight: bolder;
}
.EmployeeAnaContentIcon{
    font-size: 160px !important;
    opacity: .5;
    color: white;
    margin-bottom: -40px;
    margin-left: -23px;
}
.viewMore{
    display: flex;
    align-items: center;
    justify-content: space-between;
  background-color: transparent;
    color: white;
    padding: 0 1rem;
    opacity: .7;
}
.viewMore p{
    margin-top: 10px;
}
.viewMoreIcon{
    border: 1px solid lightgray;
    border-radius: 50%;
    color: white;
}