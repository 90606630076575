.card {
  position: relative;
  width: 380px;
  max-width: 960px;
  margin: auto;
  margin-top: 25vh;
  z-index: 10;
}
.card:before {
  content: "";
  background-color: rgb(112, 110, 110);
  position: absolute;
  max-width: 960px;
  width: 380px;
  height: 500px;
  margin: auto;
  border-radius: 20px;
  position: fixed;
  opacity: 0.9;
  z-index: -1;
}
.container {
  padding: 4rem 2rem;
  margin-top: 20px;
}
.loginHead img {
 width: 70%;
}
h1 {
  text-align: center;
}

h1 span {
  color: rgb(22, 199, 199);
}
h5 {
  color: red;
  margin: 20px 0;
  text-align: center;
}
.search {
  position: relative;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 1rem;
}
.searchIcon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16px;
}
.input {
  padding: 0.5rem;
  width: 100%;
}
.MuiInputBase-root input {
  margin-left: 4rem;
}
.MuiButton-contained {
  width: 100%;
  height: 50px;
}
