.addEmployeeFormUl {
  padding: 1rem;
}
.addEmployeeFormUl ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addEmployeeFormUl ul li {
  color: gray;
}
.activeLi {
  border-bottom: 3px solid lightskyblue;
}
.AddEmployeeFormContainer {
  border: 1px solid red;
}
.AddEmployeeFormContainerHead h6 {
  background-color: rgb(224, 64, 0);
  color: white;
  padding: 1rem;
}
